import styled, { css } from 'styled-components';
import { ReactElement } from 'react';
import { ThemeProps } from '@/styles/global';

interface InputProps {
  children?:
    | (string | ReactElement)[]
    | (string | ReactElement)
    | string[]
    | ReactElement[]
    | string
    | ReactElement;
  isError?: boolean;
}

type Props = InputProps & ThemeProps;

export const ModalWrapper = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 18.75rem;
  padding: 0 1rem;
`;

export const ModalContainer = styled.div`
  max-width: 30rem;
  background-color: ${(props: ThemeProps) => props.theme.softGreenColor};
  width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem rgba(89, 115, 114, 0.16);
  transform: rotate(3deg);
  & > div {
    transform: rotate(-6deg);
    background-color: ${(props: ThemeProps) => props.theme.whiteColor};
    width: 100%;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.25rem rgba(89, 115, 114, 0.16);
    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 30rem;
      background-color: transparent;
      width: 100%;
      color: ${(props: ThemeProps) => props.theme.blackColor};
      transform: rotate(3deg);
      padding: 2rem;
      & svg {
        margin-left: 45%;
      }
      & p {
        font-weight: 500;
        font-size: 1.375rem;
        line-height: 1.6875rem;
        max-width: 90%;
        margin: 2rem 0 1.5rem;
        text-align: center;
      }
      & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & p {
          margin-top: 2rem;
          width: 100%;
          text-align: center;
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.6875rem;
        }
      }
      & button {
        width: 10.6875rem;
        height: 3.5rem;
        margin: 1.5rem 0 2rem;
        font-size: 1.125rem;
      }
    }
  }
`;

export const StyledInput = styled.input`
  height: 3.5rem;
  width: 100%;
  padding: 0 1.5rem;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid ${(props: ThemeProps) => props.theme.lightGrayColor};
  cursor: default;
  &:focus-visible {
    cursor: text;
    outline: none;
    border-color: ${(props: ThemeProps) => props.theme.softGreenColor};
  }
  ${(props: Props) =>
    props.isError &&
    css`
      border-color: red !important;
      &::-webkit-input-placeholder {
        color: red;
      }
    `}
`;

export const ErrorMessage = styled.span`
  color: red;
`;

export const CrossContainer = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    & div:after,
    div:before {
      background-color: ${(props: ThemeProps) => props.theme.grayColor};
    }
  }
`;

export const Cross = styled.div`
  width: 0.8125rem;
  height: 0.125rem;
  border-radius: 0.375rem;
  background-color: transparent;
  &:after,
  :before {
    content: '';
    position: absolute;
    width: 1.3125rem;
    height: 0.125rem;
    background-color: ${(props: ThemeProps) => props.theme.lightGrayColor};
    border-radius: 0.375rem;
  }
  &:before {
    transform: translateY(-0.5rem);
    transform: rotate(45deg);
  }
  &:after {
    transform: translateY(0.5rem);
    transform: rotate(-45deg);
  }
`;
