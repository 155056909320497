import { useState, useEffect, useCallback } from 'react';
import {
  ModalWrapper,
  ModalContainer,
  CrossContainer,
  Cross,
  StyledInput,
  ErrorMessage,
} from './Modal.styles';
import Button from '../Button/Button';
import validator from 'validator';
import { AlertIcon } from '@/icons';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  title: string;
  buttonName: string;
  subscribe: (email: string) => Promise<any>;
  dispatchEvent?: () => void;
}

const ModalComponent = ({
  isModalOpen,
  closeModal,
  title,
  buttonName,
  subscribe,
  dispatchEvent,
}: Props) => {
  const [email, setEmail] = useState(``);
  const [error, setError] = useState(``);
  const [responseMsg, setResponseMsg] = useState(``);
  const [loading, setLoading] = useState(false);

  const close = () => {
    setError(``);
    setResponseMsg(``);
    closeModal();
  };

  const escKeyHandler = useCallback((e: any) => {
    if (e?.keyCode === 27) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isModalOpen) document.addEventListener(`keydown`, escKeyHandler);
    else {
      document.removeEventListener(`keydown`, escKeyHandler);
    }
  }, [isModalOpen, escKeyHandler]);

  const onInputChange = (e: any) => {
    setEmail(e.target.value);
    setError(``);
  };

  const onSubmit = () => {
    if (!email) {
      setError(`Required`);
    } else if (!validator.isEmail(email)) {
      setError(`Invalid email`);
    } else {
      setLoading(true);
      subscribe(email)
        .then((response) => {
          if (response.status === 201) {
            setResponseMsg(`Thank you for subscription!`);
            setTimeout(() => {
              close();
              if (dispatchEvent) {
                dispatchEvent();
              }
            }, 2000);
          }
        })
        .catch(() => {
          setResponseMsg(`Error!`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return isModalOpen ? (
    <ModalWrapper onClick={close}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <div>
          <div>
            <div>
              <AlertIcon />
              <CrossContainer onClick={close}>
                <Cross />
              </CrossContainer>
            </div>
            {responseMsg ? (
              <p>{responseMsg}</p>
            ) : (
              <>
                <p>{title}</p>
                <StyledInput
                  autoFocus
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={onInputChange}
                  isError={!!error}
                />
                <ErrorMessage>{error}</ErrorMessage>
                <Button disabled={loading} clickHandler={onSubmit}>
                  {buttonName}
                </Button>
              </>
            )}
          </div>
        </div>
      </ModalContainer>
    </ModalWrapper>
  ) : null;
};

export default ModalComponent;
