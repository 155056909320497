import styled from 'styled-components';
import { ThemeProps } from '@/styles/global';

export const Subscribe = styled.button`
  color: ${(props: ThemeProps) => props.theme.softGreenColor};
  background: none;
  border: none;
  width: 100%;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
