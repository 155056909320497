import { useState } from 'react';
import { Subscribe } from './SubscribeModal.styles';
import axios from 'axios';
import ModalComponent from '../Modal/ModalComponent';

const SubscribeModalComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const subscribe = async (email: string) => {
    const body = {
      email,
    };
    return axios.post(
      `${process.env.JOBS4DEV_API_HOST}/api/v1/subscriptions`,
      body,
      {
        headers: {
          'Content-Type': `application/json`,
        },
      },
    );
  };

  return (
    <>
      <ModalComponent
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        title="Get the most recent articles directly to your inbox"
        buttonName="Subscribe"
        subscribe={subscribe}
        dispatchEvent={() => window.dispatchEvent(new Event(`subscribed`))}
      />
      <li>
        <Subscribe onClick={openModal}>Subscribe</Subscribe>
      </li>
    </>
  );
};

export default SubscribeModalComponent;
