import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { SocialLinksList } from './Header.styles';
import { LinkButton } from '../Button/Button';
import { Instagram, Twitter, Telegram } from '@/icons';
import { TCategory } from '@/utils/categories';
import isBlog from '@/utils/isBlog';
import SubscribeModalComponent from '../SubscribeModal/SubscribeModalComponent';

interface Props {
  currentCategory?: TCategory | undefined;
  onClick?: () => void;
}

type SocialItem = {
  link: string;
  icon: ReactElement;
  label: string;
};

interface Social {
  instagram: SocialItem;
  telegram: SocialItem;
  twitter: SocialItem;
}

const defaultSocialMediaLinks: Social = {
  instagram: {
    link: `https://www.instagram.com/jobshash/`,
    icon: <Instagram />,
    label: `instagram`,
  },
  twitter: {
    link: `https://twitter.com/jobs_in_js`,
    icon: <Twitter />,
    label: `twitter`,
  },
  telegram: {
    link: `https://t.me/jobs_in_js`,
    icon: <Telegram />,
    label: `telegram`,
  },
};

const SocialLinks = ({ currentCategory, onClick }: Props) => {
  const router = useRouter();
  return (
    <SocialLinksList>
      {Object.keys(defaultSocialMediaLinks).map((key) => (
        <li key={key} onClick={onClick}>
          <a
            href={
              currentCategory?.[key as keyof Social] ||
              defaultSocialMediaLinks[key as keyof Social].link
            }
            target="_blank"
            rel="noreferrer"
            aria-label={defaultSocialMediaLinks?.[key as keyof Social].label}
          >
            {defaultSocialMediaLinks[key as keyof Social].icon}
          </a>
        </li>
      ))}
      {isBlog(router.pathname) ? (
        <SubscribeModalComponent />
      ) : (
        <li onClick={onClick}>
          <LinkButton href="/postajob">Post a Job</LinkButton>
        </li>
      )}
    </SocialLinksList>
  );
};
export default React.memo(SocialLinks);
